import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Segmento } from "../models/segmento";

@Injectable({ providedIn: 'root' })
export class SegmentoService {

    constructor(private readonly http: HttpClient) { }

    buscaSegmentos(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/segmento/0/1000`)
        .pipe(
            tap(response => {
            return response
        } ))
    }

    buscaSegmentoPorId(id: string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/segmento/${id}`)
        .pipe(
            tap(response => {
            return response
        } ))
    }

    salvaSegmento(segmento: Segmento): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/segmento`, segmento)
        .pipe(
            tap(response => {
                return response
            })
        )
    }

    removeSegmento(idSegmento: string): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/segmento/${idSegmento}`)
        .pipe(
            tap(response => {
                return response
            })
        )
    }
}