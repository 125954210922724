import { Component, OnDestroy, OnInit, } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Recurso } from 'src/app/models/recurso';
import { RecursoService } from 'src/app/services/recurso.service';

@Component({
  selector: 'cadastrorecurso',
  templateUrl: 'cadastro.template.html'
})
export class CadastroRecursoComponent implements OnDestroy, OnInit  {

  public registerForm: FormGroup
  public submitted = false
  public idRecurso: string

  public nav:any;

  public constructor(
    private readonly recursoService: RecursoService,
    private readonly activeRoute: ActivatedRoute,
    private readonly route: Router,
    private formBuilder: FormBuilder
  ) {
    this.nav = document.querySelector('nav.navbar');
    this.activeRoute.params.subscribe(params => this.idRecurso = params.id);
  }

  public ngOnInit():any {
    this.nav.className += " white-bg";

    this.registerForm = this.formBuilder.group({
      id: null,
      nome: [null, Validators.required],
      descricao: null,
      valor: [0.0, Validators.min(0.1)],
      unidadeMedida: [0, Validators.min(1)],
    })

    if (this.idRecurso) {
      const response = this.recursoService.buscaRecursoPorId(this.idRecurso)
      response.subscribe(obs => {
        this.preencheForm(obs)
      })
    }
  }

  get f() { return this.registerForm.controls }

  public ngOnDestroy():any {
    this.nav.classList.remove("white-bg");
  }

  onSubmit() {
    this.submitted = true
    if (this.registerForm.invalid) return
    this.salvaRecurso()
  }

  onReset() {
    this.submitted = false
    this.registerForm.reset()
  }

  private preencheForm(recurso: Recurso): void {
    this.registerForm.patchValue({
      id: recurso.id,
      nome: recurso.nome,
      descricao: recurso.descricao,
      valor: recurso.valor,
      unidadeMedida: this.buscaChaveUnidadeMedida(recurso.unidadeMedida),
    })
  }

  private preencheRecurso(): Recurso {
    const recursoForm = this.registerForm.value

    const recurso = new Recurso()
    recurso.id = recursoForm['id']
    recurso.nome = recursoForm['nome']
    recurso.descricao = recursoForm['descricao']
    recurso.valor = recursoForm['valor']
    recurso.unidadeMedida = this.buscaValorUnidadeMedida(recursoForm['unidadeMedida'])

    return recurso
  }

  private salvaRecurso() {
    const recurso = this.preencheRecurso()
    this.recursoService.salvaRecurso(recurso).subscribe(() => {
      this.limpaForm()
    })
  }

  private limpaForm() {
    this.onReset()
    this.route.navigate(['/cadastros/recurso'])
  }

  private buscaValorUnidadeMedida(valorUnidade: string): string {
    const tabelaUnidade = new Map<string, string>()
    tabelaUnidade.set("1", "HORA")
    tabelaUnidade.set("2", "DIA")
    tabelaUnidade.set("3", "SEMANA")
    tabelaUnidade.set("4", "MES")
    tabelaUnidade.set("5", "ANO")
    return tabelaUnidade.get(valorUnidade)
  }

  private buscaChaveUnidadeMedida(valorUnidade: string): string {
    const tabelaUnidade = new Map<string, string>()
    tabelaUnidade.set("HORA", "1")
    tabelaUnidade.set("DIA", "2")
    tabelaUnidade.set("SEMANA", "3")
    tabelaUnidade.set("MES", "4")
    tabelaUnidade.set("ANO", "5")
    return tabelaUnidade.get(valorUnidade)
  }

}
