import { Component, OnDestroy, OnInit, } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Fornecedor } from 'src/app/models/fornecedor';
import { FornecedorService } from 'src/app/services/fornecedor.service';

@Component({
  selector: 'cadastrofornecedor',
  templateUrl: 'cadastro.template.html'
})
export class CadastroFornecedorComponent implements OnDestroy, OnInit  {

  public registerForm: FormGroup
  public submitted = false
  public idFornecedor: string

  public nav:any;

  public constructor(
    private readonly fornecedorService: FornecedorService,
    private readonly activeRoute: ActivatedRoute,
    private readonly route: Router,
    private formBuilder: FormBuilder
  ) {
    this.nav = document.querySelector('nav.navbar');
    this.activeRoute.params.subscribe(params => this.idFornecedor = params.id);
  }

  public ngOnInit():any {
    this.nav.className += " white-bg";

    this.registerForm = this.formBuilder.group({
      id: null,
      nome: [null, Validators.required],
      cpfCnpj: null,
      telefone: null,
      celular: null,
      email: [null, Validators.email],
      fisicaJuridica: true,
      complemento: null,
      rua: null,
      numero: null,
      cep: null,
      bairro: null,
      cidade: null,
    })

    if (this.idFornecedor) {
      const response = this.fornecedorService.buscaFornecedorPorId(this.idFornecedor)
      response.subscribe(obs => {
        this.preencheForm(obs)
      })
    }
  }

  get f() { return this.registerForm.controls }

  public ngOnDestroy():any {
    this.nav.classList.remove("white-bg");
  }

  onSubmit() {
    this.submitted = true
    if (this.registerForm.invalid) return
    this.salvaFornecedor()
  }

  onReset() {
    this.submitted = false
    this.registerForm.reset()
  }

  private preencheForm(fornecedor: Fornecedor): void {
    this.registerForm.patchValue({
      id: fornecedor.id,
      nome: fornecedor.nome,
      cpfCnpj: fornecedor.cpfCnpj,
      telefone: fornecedor.telefone,
      celular: fornecedor.celular,
      email: fornecedor.email,
      fisicaJuridica: fornecedor.fisicaJuridica === "Jurídica",
      complemento: fornecedor.endereco.complemento,
      rua: fornecedor.endereco.rua,
      numero: fornecedor.endereco.numero,
      cep: fornecedor.endereco.cep,
      bairro: fornecedor.endereco.bairro,
      cidade: fornecedor.endereco.cidade,
    })
  }

  private preencheFornecedor(): Fornecedor {
    const moradorForm = this.registerForm.value

    const fornecedor = new Fornecedor()
    fornecedor.id = moradorForm['id']
    fornecedor.nome = moradorForm['nome']
    fornecedor.cpfCnpj = moradorForm['cpfCnpj']
    fornecedor.telefone = moradorForm['telefone']
    fornecedor.celular = moradorForm['celular']
    fornecedor.email = moradorForm['email']
    fornecedor.fisicaJuridica = Boolean(moradorForm['fisicaJuridica']) ? "Jurídica" : "Física"
    fornecedor.endereco.complemento = moradorForm['complemento']
    fornecedor.endereco.rua = moradorForm['rua']
    fornecedor.endereco.numero = moradorForm['numero']
    fornecedor.endereco.cep = moradorForm['cep']
    fornecedor.endereco.bairro = moradorForm['bairro']
    fornecedor.endereco.cidade = moradorForm['cidade']

    return fornecedor
  }

  private salvaFornecedor() {
    const fornecedor = this.preencheFornecedor()
    this.fornecedorService.salvaFornecedor(fornecedor).subscribe(() => {
      this.limpaForm()
    })
  }

  private limpaForm() {
    this.onReset()
    this.route.navigate(['/cadastros/fornecedor'])
  }

}
