import { Component, OnDestroy, OnInit, } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Morador } from 'src/app/models/morador';
import { ContasReceberService } from 'src/app/services/contasReceber.service';
import { MoradorService } from 'src/app/services/morador.service';
import * as moment from 'moment';
import { ContasReceber } from 'src/app/models/contasReceber';

@Component({
  selector: 'cadastrocontasreceber',
  templateUrl: 'cadastro.template.html'
})
export class CadastroContasReceberComponent implements OnDestroy, OnInit  {

  public registerForm: FormGroup
  public submitted = false
  public idContasReceber: string
  public moradores: Morador[]
  public moradorSelecionado: Morador = new Morador()

  public nav:any;

  public constructor(
    private readonly moradorService: MoradorService,
    private readonly contasReceberService: ContasReceberService,
    private readonly activeRoute: ActivatedRoute,
    private readonly route: Router,
    private formBuilder: FormBuilder
  ) {
    this.nav = document.querySelector('nav.navbar');
    this.activeRoute.params.subscribe(params => this.idContasReceber = params.id);
  }

  get f() { return this.registerForm.controls }

  public ngOnInit():any {
    this.nav.className += " white-bg";

    this.carregaMoradores()

    this.registerForm = this.formBuilder.group({
      id: null,
      numero: null,
      digito: null,
      nome: [null, Validators.required],
      historico: null,
      dataEmissao: [null, Validators.required],
      dataVencimento: [null, Validators.required],
      dataPagamento: null,
      especiePagamento: "0",
      valor: [0.0, Validators.min(0.1)],
      status: "PENDENTE",
      idMorador: ["0", Validators.minLength(2)],
    }, {
      validator: [ValidaData('dataEmissao'), ValidaData('dataVencimento'), ValidaData('dataPagamento')]
    })

    if (this.idContasReceber) {
      const response = this.contasReceberService.buscaContasReceberPorId(this.idContasReceber)
      response.subscribe(obs => this.preencheForm(obs))
    }
  }

  public ngOnDestroy():any {
    this.nav.classList.remove("white-bg");
  }

  onSubmit() {
    this.submitted = true
    if (this.registerForm.invalid) return
    this.salvaContasReceber()
  }

  onReset() {
    this.submitted = false
    this.registerForm.reset()
  }

  private salvaContasReceber() {
    const contasReceber = this.preencheContasReceber()
    this.contasReceberService.salvaContasReceber(contasReceber).subscribe(() => {
      this.limpaForm()
    })
  }

  private preencheForm(contasReceber: ContasReceber): void {
    this.registerForm.patchValue({
      id: contasReceber.id,
      numero: contasReceber.numero,
      digito: contasReceber.digito,
      nome: contasReceber.nome,
      historico: contasReceber.historico,
      dataEmissao: contasReceber.dataEmissao,
      dataVencimento: contasReceber.dataVencimento,
      dataPagamento: contasReceber.dataPagamento,
      especiePagamento: this.buscaChaveEspecie(contasReceber.especiePagamento),
      valor: contasReceber.valor,
      status: contasReceber.status,
      idMorador: contasReceber.idOrigem,
    })

    this.moradorService.buscaMoradorPorId(contasReceber.idOrigem)
    .subscribe(obs => this.moradorSelecionado = obs)
  }

  private preencheContasReceber(): ContasReceber {
    const contasReceberForm = this.registerForm.value
    const numero = contasReceberForm['numero']
    const contasReceber = new ContasReceber()

    contasReceber.id = contasReceberForm['id']
    if(numero) {
      contasReceber.numero = numero 
    }
    contasReceber.nome = contasReceberForm['nome']
    contasReceber.historico = contasReceberForm['historico']
    contasReceber.dataEmissao = contasReceberForm['dataEmissao']
    contasReceber.dataVencimento = contasReceberForm['dataVencimento']
    contasReceber.dataPagamento = contasReceberForm['dataPagamento']
    contasReceber.especiePagamento = this.buscaValorEspecie(contasReceberForm['especiePagamento'])
    contasReceber.valor = contasReceberForm['valor']
    contasReceber.status = contasReceberForm['status']
    contasReceber.idOrigem = contasReceberForm['idMorador']

    return contasReceber
  }

  private carregaMoradores() {
    const response = this.moradorService.buscaMoradores()
    response.subscribe(obs => {
      this.moradores = obs.content

      const morador = new Morador()
      morador.id = "0"
      morador.nome = "Selecione"
      this.moradores.unshift(morador)
    })
  }

  private buscaValorEspecie(chaveEspecie: string): string {
    const tabelaUnidade = new Map<string, string>()
    tabelaUnidade.set("1", "DINHEIRO")
    tabelaUnidade.set("2", "DEBITO")
    tabelaUnidade.set("3", "CREDITO")
    tabelaUnidade.set("4", "CHEQUE")
    tabelaUnidade.set("5", "PIX")
    return tabelaUnidade.get(chaveEspecie)
  }

  private buscaChaveEspecie(valorEspecie: string): string {
    if(valorEspecie) {
      const tabelaUnidade = new Map<string, string>()
      tabelaUnidade.set("DINHEIRO", "1")
      tabelaUnidade.set("DEBITO", "2")
      tabelaUnidade.set("CREDITO", "3")
      tabelaUnidade.set("CHEQUE", "4")
      tabelaUnidade.set("PIX", "5")
      return tabelaUnidade.get(valorEspecie)
    }

    return "0"
  }

  private limpaForm() {
    this.onReset()
    this.route.navigate(['/financeiro/contasreceber'])
  }

  exibeDadosMorador(idSelecionado: string) {
    if(idSelecionado !== "0") {
      this.moradorSelecionado = this.moradores.find(morador => morador.id === idSelecionado)

      const dataAtual = moment().parseZone()
      
      this.registerForm.patchValue({
        nome: this.moradorSelecionado.nome,
        historico: this.moradorSelecionado.associado ? "Mensalidade associado" : "",
        dataEmissao: dataAtual.format("DD/MM/YYYY"),
        dataVencimento: this.moradorSelecionado.associado ? dataAtual.set("date", this.moradorSelecionado.diaPagamento).add(1, 'months').format("DD/MM/YYYY") : "",
      })

    } else {
      this.registerForm.patchValue({
        nome: "",
        historico: "",
        dataEmissao: "",
        dataVencimento: "",
      })

      const moradorVazio = new Morador()
      // moradorVazio.email = ""
      // moradorVazio.celular = ""
      // moradorVazio.telefone = ""
      this.moradorSelecionado = moradorVazio
    }
  }

}

function ValidaData(campoParaValidar: string) {
  return (formGroup: FormGroup) => {
    const matchingControl = formGroup.controls[campoParaValidar]

    if (matchingControl.value) {
      if (matchingControl.errors && !matchingControl.errors.mustMatch) return

      const data = matchingControl.value

      if (data.length === 10 && moment(data, 'DD/MM/YYYY').isValid()) {
        matchingControl.setErrors(null)
      } else if (data.length === 10) {
        matchingControl.setErrors({ mustMatch: true })
      }

    }
  }
}
