import { Component, OnDestroy, OnInit, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Morador } from 'src/app/models/morador';
import { MoradorService } from 'src/app/services/morador.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { SegmentoService } from 'src/app/services/segmento.service';
import { Segmento } from 'src/app/models/segmento';
import { Comercio } from 'src/app/models/comercio';
import { CadastroMoradorRequest } from 'src/app/models/CadastroMoradorRequest';
import { ComercioService } from 'src/app/services/comercio.service';

@Component({
  selector: 'cadastromorador',
  templateUrl: 'cadastro.template.html'
})
export class CadastroMoradorComponent implements OnDestroy, OnInit {

  public registerForm: FormGroup
  public submitted = false
  public comercianteForm = false
  public idMorador: string
  public segmentos: Segmento[]

  public nav: any;

  public constructor(
    private readonly moradorService: MoradorService,
    private readonly segmentoService: SegmentoService,
    private readonly comercioService: ComercioService,
    private readonly activeRoute: ActivatedRoute,
    private readonly route: Router,
    private formBuilder: FormBuilder
  ) {
    this.nav = document.querySelector('nav.navbar');
    this.activeRoute.params.subscribe(params => this.idMorador = params.id);
  }

  public ngOnInit(): any {
    this.nav.className += " white-bg";

    this.carregaSegmentos()

    this.registerForm = this.formBuilder.group({
      id: null,
      nome: [null, Validators.required],
      cpf: null,
      dataNascimento: null,
      telefoneResidencial: null,
      telefoneCelular: [null, [Validators.required]],
      email: [null, Validators.email],
      complemento: null,
      rua: null,
      numero: null,
      cep: null,
      bairro: null,
      cidade: null,
      associado: false,
      diaPagamento: 0,
      comerciante: false,



      idComercio: null,
      nomeFantasia: [null],
      razaoSocial: null,
      inicioAtividade: null,
      cnpj: null,
      ie: null,
      telefone: null,
      celular: [null],
      emailComercio: [null, Validators.email],
      observacao: null,
      responsavel: null,
      idSegmento: ["0"],
      complementoComercio: null,
      ruaComercio: null,
      numeroComercio: null,
      cepComercio: null,
      bairroComercio: null,
      cidadeComercio: null,
    }, {
      validator: [ValidaVencimento(), ValidaData('dataNascimento'), ValidaData('inicioAtividade')]
    })

    this.setComercioValidators()

    if (this.idMorador) {
      const response = this.moradorService.buscaMoradorPorId(this.idMorador)
      response.subscribe(obs => {
        this.preencheForm(obs)


        this.comercioService.buscaComercioPorId(obs.idComercio)
        .subscribe(obs => {
          this.preencheFormComercio(obs)
        })
      })
    }
  }

  get f() { return this.registerForm.controls }

  ngOnDestroy(): any {
    this.nav.classList.remove("white-bg")
  }

  onSubmit() {
    this.submitted = true
    if (this.registerForm.invalid) return

    const isAssociado = Boolean(this.registerForm.controls['associado'].value)
    if (!isAssociado) {
      this.registerForm.patchValue({ diaPagamento: 0 })
    }

    this.salvaMorador()
  }

  onReset() {
    this.submitted = false
    this.comercianteForm = false
    this.registerForm.reset()
  }

  exibeComercioForm(teste: boolean) {
    this.comercianteForm = this.registerForm.value['comerciante']
  }

  private carregaSegmentos() {
    const response = this.segmentoService.buscaSegmentos()
    response.subscribe(obs => {
      this.segmentos = obs.content

      const segmento = new Segmento()
      segmento.id = "0"
      segmento.nome = "Selecione"
      this.segmentos.unshift(segmento)
    })
  }

  private preencheForm(morador: Morador): void {
    this.registerForm.patchValue({
      id: morador.id,
      nome: morador.nome,
      cpf: morador.cpf,
      dataNascimento: morador.dataNascimento ? moment(morador.dataNascimento, 'DD/MM/YYYY').parseZone().format('DD/MM/YYYY') : null,
      telefoneResidencial: morador.telefoneResidencial,
      telefoneCelular: morador.telefoneCelular,
      email: morador.email,
      associado: morador.associado,
      diaPagamento: morador.diaPagamento,
      complemento: morador.endereco.complemento,
      rua: morador.endereco.rua,
      numero: morador.endereco.numero,
      cep: morador.endereco.cep,
      bairro: morador.endereco.bairro,
      cidade: morador.endereco.cidade,
      comerciante: morador.ehComerciante,
    })

    this.comercianteForm = morador.ehComerciante
  }

  private preencheFormComercio(comercio: Comercio): void {
    this.registerForm.patchValue({
      idComercio: comercio.id,
      nomeFantasia: comercio.nomeFantasia,
      razaoSocial: comercio.razaoSocial,
      inicioAtividade: comercio.inicioAtividade,
      cnpj: comercio.cnpj,
      ie: comercio.ie,
      telefone: comercio.telefone,
      celular: comercio.celular,
      emailComercio: comercio.email,
      observacao: comercio.observacao,
      responsavel: comercio.responsavel,
      idSegmento: comercio.segmento.id,
      complementoComercio: comercio.endereco.complemento,
      ruaComercio: comercio.endereco.rua,
      numeroComercio: comercio.endereco.numero,
      cepComercio: comercio.endereco.cep,
      bairroComercio: comercio.endereco.bairro,
      cidadeComercio: comercio.endereco.cidade,
    })
  }

  private preencheMorador(): Morador {
    const moradorForm = this.registerForm.value

    const morador = new Morador()
    morador.id = moradorForm['id']
    morador.nome = moradorForm['nome']
    morador.cpf = moradorForm['cpf']
    morador.dataNascimento = moradorForm['dataNascimento'] ? moment(moradorForm['dataNascimento'], "DD/MM/YYYY").parseZone().format("DD/MM/YYYY") : null
    morador.telefoneResidencial = moradorForm['telefoneResidencial']
    morador.telefoneCelular = moradorForm['telefoneCelular']
    morador.email = moradorForm['email']
    morador.associado = moradorForm['associado']
    morador.diaPagamento = moradorForm['diaPagamento']
    morador.endereco.complemento = moradorForm['complemento']
    morador.endereco.rua = moradorForm['rua']
    morador.endereco.numero = moradorForm['numero']
    morador.endereco.cep = moradorForm['cep']
    morador.endereco.bairro = moradorForm['bairro']
    morador.endereco.cidade = moradorForm['cidade']

    return morador
  }

  private preencheComercio(): Comercio {
    const comercioForm = this.registerForm.value

    const comercio = new Comercio()

    comercio.id = comercioForm['idComercio']
    comercio.nomeFantasia = comercioForm['nomeFantasia']
    comercio.razaoSocial = comercioForm['razaoSocial']
    comercio.inicioAtividade = comercioForm['inicioAtividade']
    comercio.cnpj = comercioForm['cnpj']
    comercio.ie = comercioForm['ie']
    comercio.telefone = comercioForm['telefone']
    comercio.celular = comercioForm['celular']
    comercio.email = comercioForm['emailComercio']
    comercio.observacao = comercioForm['observacao']
    comercio.dataCadastro = comercioForm['dataCadastro']
    comercio.responsavel = comercioForm['nome']
    const idSegmento = comercioForm['idSegmento']
    comercio.segmento = this.segmentos.find(segmento => segmento.id === idSegmento)
    comercio.endereco.complemento = comercioForm['complementoComercio']
    comercio.endereco.rua = comercioForm['ruaComercio']
    comercio.endereco.numero = comercioForm['numeroComercio']
    comercio.endereco.cep = comercioForm['cepComercio']
    comercio.endereco.bairro = comercioForm['bairroComercio']
    comercio.endereco.cidade = comercioForm['cidadeComercio']

    return comercio
  }

  private salvaMorador() {
    const request = new CadastroMoradorRequest()
    request.morador = this.preencheMorador()

    if(this.comercianteForm) {
      request.comercio = this.preencheComercio()
    }

    this.moradorService.salvaMorador(request).subscribe(() => {
      this.limpaForm()
    })
  }

  private limpaForm() {
    this.onReset()
    this.route.navigate(['/cadastros/morador'])
  }

  private setComercioValidators() {
    const nomeFantasia = this.registerForm.get('nomeFantasia')
    const celular = this.registerForm.get('celular')
    const idSegmento = this.registerForm.get('idSegmento')
  
    this.registerForm.get('comerciante').valueChanges
      .subscribe(comerciante => {
  
        if (comerciante === false) {
          console.log('reseta validators')
          nomeFantasia.setValidators(null)
          celular.setValidators(null)
          idSegmento.setValidators(null)
        }
  
        if (comerciante === true) {
          console.log('adiciona validators')
          nomeFantasia.setValidators([Validators.required])
          celular.setValidators([Validators.required])
          idSegmento.setValidators([Validators.minLength(2)])
        }
  
        nomeFantasia.updateValueAndValidity()
        celular.updateValueAndValidity()
        idSegmento.updateValueAndValidity()
      });
  }
}

function ValidaVencimento() {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls['associado']
    const matchingControl = formGroup.controls['diaPagamento']

    if (matchingControl.errors && !matchingControl.errors.mustMatch) return

    if (control.value && matchingControl.value === 0) {
      matchingControl.setErrors({ mustMatch: true })
    } else {
      matchingControl.setErrors(null)
    }
  }
}

function ValidaData(campoParaValidar: string) {
  return (formGroup: FormGroup) => {
    const matchingControl = formGroup.controls[campoParaValidar]

    if (matchingControl.value) {
      if (matchingControl.errors && !matchingControl.errors.mustMatch) return

      const data = matchingControl.value

      if (data.length === 10 && moment(data, 'DD/MM/YYYY').isValid()) {
        matchingControl.setErrors(null)
      } else if (data.length === 10) {
        matchingControl.setErrors({ mustMatch: true })
      }

    }
  }
}

