import { Component, OnDestroy, OnInit, } from '@angular/core';
import { Recurso } from 'src/app/models/recurso';
import { RecursoService } from 'src/app/services/recurso.service';

@Component({
  selector: 'recurso',
  templateUrl: 'recurso.template.html'
})
export class RecursoComponent implements OnDestroy, OnInit  {

  public nav:any;
  public recursos: Recurso[]

  public constructor(private readonly recursoService: RecursoService) {
    this.nav = document.querySelector('nav.navbar');
    const response = this.recursoService.buscaRecursos()
    response.subscribe(obs => this.recursos = obs.content.sort((a,b) => {
      if(a.nome.toLowerCase > b.nome.toLowerCase) return 1
      else if(a.nome.toLowerCase < b.nome.toLowerCase) return -1
      return 0
    }))
  }

  public ngOnInit():any {
    this.nav.className += " white-bg";
  }

  public ngOnDestroy():any {
    this.nav.classList.remove("white-bg");
  }

  public removeRecurso(idRecurso: string): void {
    const index = this.recursos.findIndex( i => i.id === idRecurso)
    const response = this.recursoService.removeRecurso(idRecurso)
    response.subscribe(obs => this.recursos.splice(index, 1))
  }

}
