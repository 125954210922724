import { Component, OnDestroy, OnInit, } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Morador } from 'src/app/models/morador';
import { Ocorrencia } from 'src/app/models/ocorrencia';
import { MoradorService } from 'src/app/services/morador.service';
import { OcorrenciaService } from 'src/app/services/ocorrencia.service';

@Component({
  selector: 'cadastromorador',
  templateUrl: 'cadastro.template.html'
})
export class CadastroOcorrenciaComponent implements OnDestroy, OnInit  {

  public registerForm: FormGroup
  public submitted = false
  public idOcorrencia: string
  public moradores: Morador[]
  public moradorSelecionado: Morador = new Morador()

  public nav:any;

  public config = {}

  public constructor(
    private readonly moradorService: MoradorService,
    private readonly ocorrenciaService: OcorrenciaService,
    private readonly activeRoute: ActivatedRoute,
    private readonly route: Router,
    private formBuilder: FormBuilder
  ) {
    this.nav = document.querySelector('nav.navbar');
    this.activeRoute.params.subscribe(params => this.idOcorrencia = params.id);
  }

  get f() { return this.registerForm.controls }

  public ngOnInit():any {
    this.nav.className += " white-bg";

    this.carregaMoradores()

    this.config = {
      placeholder: '',
      tabsize: 2,
      height: '700',
      toolbar: [
          ['misc', ['undo', 'redo']],
          ['style', ['bold', 'italic', 'underline', 'clear']],
          ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
          ['fontsize', ['fontname', 'fontsize', 'color']],
          ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
          ['insert', ['table', 'link', 'hr']]
      ],
      fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
    }

    this.registerForm = this.formBuilder.group({
      id: null,
      local: null,
      pontoReferencia: null,
      dataInicio: [null, Validators.required],
      dataFim: null,
      titulo: [null, Validators.required],
      descricao: [null, Validators.required],
      nomeMorador: null,
      prioridade: 'Baixa',
      idMorador: ["0", Validators.minLength(2)],
    }, {
      validator: [ValidaData('dataInicio'), ValidaData('dataFim')]
    })

    if (this.idOcorrencia) {
      const response = this.ocorrenciaService.buscaOcorrenciaPorId(this.idOcorrencia)
      response.subscribe(obs => this.preencheForm(obs))
    }
  }

  public ngOnDestroy():any {
    this.nav.classList.remove("white-bg");
  }

  onSubmit() {
    this.submitted = true
    if (this.registerForm.invalid) return
    this.salvaOcorrencia()
  }

  onReset() {
    this.submitted = false
    this.registerForm.reset()
  }

  private salvaOcorrencia() {
    const ocorrencia = this.preecheOcorrencia()
    this.ocorrenciaService.salvaOcorrencia(ocorrencia).subscribe(() => {
      this.limpaForm()
    })
  }

  private limpaForm() {
    this.onReset()
    this.route.navigate(['/ocorrencia'])
  }

  private preecheOcorrencia(): Ocorrencia {
    const ocorrenciaForm = this.registerForm.value
    const ocorrencia = new Ocorrencia()

    ocorrencia.id = ocorrenciaForm['id']
    ocorrencia.local = ocorrenciaForm['local']
    ocorrencia.pontoReferencia = ocorrenciaForm['pontoReferencia']
    ocorrencia.dataInicio = ocorrenciaForm['dataInicio']
    ocorrencia.dataFim = ocorrenciaForm['dataFim']
    ocorrencia.prioridade = ocorrenciaForm['prioridade']
    ocorrencia.titulo = ocorrenciaForm['titulo']
    ocorrencia.descricao = ocorrenciaForm['descricao']
    ocorrencia.idMorador = ocorrenciaForm['idMorador']
    ocorrencia.nomeMorador = this.moradorSelecionado.nome

    return ocorrencia
  }

  private preencheForm(ocorrencia: Ocorrencia): void {
    this.registerForm.patchValue({
      id: ocorrencia.id,
      local: ocorrencia.local,
      pontoReferencia: ocorrencia.pontoReferencia,
      dataInicio: ocorrencia.dataInicio,
      dataFim: ocorrencia.dataFim,
      titulo: ocorrencia.titulo,
      descricao: ocorrencia.descricao,
      nomeMorador: ocorrencia.nomeMorador,
      prioridade: ocorrencia.prioridade,
      idMorador: ocorrencia.idMorador,
    })

    this.moradorService.buscaMoradorPorId(ocorrencia.idMorador)
    .subscribe(obs => this.moradorSelecionado = obs)
  }

  private carregaMoradores() {
    const response = this.moradorService.buscaMoradores()
    response.subscribe(obs => {
      this.moradores = obs.content

      const morador = new Morador()
      morador.id = "0"
      morador.nome = "Selecione"
      this.moradores.unshift(morador)
    })
  }

  selecionaMorador(idSelecionado: string) {
    if(idSelecionado !== "0") {
      this.moradorSelecionado = this.moradores.find(morador => morador.id === idSelecionado)
    } else {
      const moradorVazio = new Morador()
      this.moradorSelecionado = moradorVazio
    }
  }

}

function ValidaData(campoParaValidar: string) {
  return (formGroup: FormGroup) => {
    const matchingControl = formGroup.controls[campoParaValidar]

    if (matchingControl.value) {
      if (matchingControl.errors && !matchingControl.errors.mustMatch) return

      const data = matchingControl.value

      if (data.length === 10 && moment(data, 'DD/MM/YYYY').isValid()) {
        matchingControl.setErrors(null)
      } else if (data.length === 10) {
        matchingControl.setErrors({ mustMatch: true })
      }

    }
  }
}
