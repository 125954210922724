import { Component, OnDestroy, OnInit, } from '@angular/core';
import { ContasPagar } from 'src/app/models/contasPagar';
import { ContasPagarService } from 'src/app/services/contasPagar.service';

@Component({
  selector: 'contaspagar',
  templateUrl: 'contaspagar.template.html'
})
export class ContasPagarComponent implements OnDestroy, OnInit  {

  public nav:any;
  public contas: ContasPagar[]

  public constructor(private readonly contasPagarService: ContasPagarService) {
    this.nav = document.querySelector('nav.navbar');
  }

  public ngOnInit():any {
    this.nav.className += " white-bg";
    const response = this.contasPagarService.buscaContasPagar()
    response.subscribe(obs => this.contas = obs.content)
  }

  public ngOnDestroy():any {
    this.nav.classList.remove("white-bg");
  }

  public removeContaPagar(idContaPagar: string): void {
    const index = this.contas.findIndex( i => i.id === idContaPagar)
    const response = this.contasPagarService.removeContasPagar(idContaPagar)
    response.subscribe(obs => this.contas.splice(index, 1))
  }

}
