import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {RouterModule} from "@angular/router";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FluxoCaixaComponent } from "./fluxocaixa.component";

@NgModule({
  declarations: [
    FluxoCaixaComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    FluxoCaixaComponent
  ]
})

export class FluxoCaixaModule {}
