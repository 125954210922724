export class Dashboard {
    totalMoradores: number = 0
    totalAssociados: number = 0
    totalComercios: number = 0
    ocorrencias: DashboardOcorrencia[] = []
    contasReceber: DasboardContas[] = []
    contasPagar: DasboardContas[] = []
}

export class DashboardOcorrencia{
    id: string
    titulo: string
    descricao: string
    dataInicio: string
}

export class DasboardContas{
    id: string
    nome: string
    valor: number
    vencimento: string
}