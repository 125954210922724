import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { tap } from "rxjs/operators"
import { environment } from "src/environments/environment"
import { ContasPagar } from "../models/contasPagar"
import { ContasReceber } from "../models/contasReceber"

@Injectable({ providedIn: 'root' })
export class ContasReceberService {

    constructor(private readonly http: HttpClient) { }

    buscaContasReceber(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/conta-receber/0/1000`)
        .pipe(
            tap(response => {
            return response
        } ))
    }

    buscaContasReceberPorId(id: string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/conta-receber/${id}`)
        .pipe(
            tap(response => {
            return response
        } ))
    }

    salvaContasReceber(contasReceber: ContasReceber): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/conta-receber`, contasReceber)
        .pipe(
            tap(response => {
                return response
            })
        )
    }

    removeContasReceber(idContasReceber: string): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/conta-receber/${idContasReceber}`)
        .pipe(
            tap(response => {
                return response
            })
        )
    }
}