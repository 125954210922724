import { Component, OnDestroy, OnInit, } from '@angular/core';
import { Fornecedor } from 'src/app/models/fornecedor';
import { FornecedorService } from 'src/app/services/fornecedor.service';

@Component({
  selector: 'fornecedor',
  templateUrl: 'fornecedor.template.html'
})
export class FornecedorComponent implements OnDestroy, OnInit  {

  public nav:any;
  public fornecedores: Fornecedor[]

  public constructor(private readonly fornecedorService: FornecedorService) {
    this.nav = document.querySelector('nav.navbar');
    const response = this.fornecedorService.buscaFornecedores()
    response.subscribe(obs => this.fornecedores = obs.content.sort((a,b) => {
      if(a.nome.toLowerCase > b.nome.toLowerCase) return 1
      else if(a.nome.toLowerCase < b.nome.toLowerCase) return -1
      return 0
    }))
  }

  public ngOnInit():any {
    this.nav.className += " white-bg";
  }

  public ngOnDestroy():any {
    this.nav.classList.remove("white-bg");
  }

  public removeFornecedor(idFornecedor: string): void {
    const index = this.fornecedores.findIndex( i => i.id === idFornecedor)
    const response = this.fornecedorService.removeFornecedor(idFornecedor)
    response.subscribe(obs => this.fornecedores.splice(index, 1))
  }

}
