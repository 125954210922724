import { Component, OnDestroy, OnInit, } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Usuario } from 'src/app/models/usuario';
import { Perfil } from 'src/app/models/perfil';
import { UsuarioService } from 'src/app/services/usuario.service';
import { PerfilService } from 'src/app/services/perfil.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'cadastrousuario',
  templateUrl: 'cadastro.template.html'
})
export class CadastroUsuarioComponent implements OnDestroy, OnInit  {

  public registerForm: FormGroup
  public submitted = false

  public nav:any;
  public idUsuario: string
  public perfis: Perfil[]

  public constructor(
    private readonly usuarioService: UsuarioService, 
    private readonly activeRoute: ActivatedRoute,
    private readonly route: Router,
    private formBuilder: FormBuilder,
    private readonly perfilService: PerfilService) 
  {
    
    this.nav = document.querySelector('nav.navbar');
    this.activeRoute.params.subscribe(params => this.idUsuario = params.id);
  }

  get f() { return this.registerForm.controls }

  onSubmit() {
    this.submitted = true
    if (this.registerForm.invalid) return
    this.salvaUsuario()
  }

  public ngOnInit():any {
    this.nav.className += " white-bg";

    this.registerForm = this.formBuilder.group({
      id: null,
      nome: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      telefone: '',
      senha: null
    },{
      validator: [ValidaSenha()]
    })

    this.buscarUsuario()
  }

  public ngOnDestroy():any {
    this.nav.classList.remove("white-bg");
  }

  private buscarUsuario():void {
    if(this.idUsuario) {
      const response = this.usuarioService.buscaUsuarioPorId(this.idUsuario)
      response.subscribe(obs => {
        this.preencherForm(obs)
      })
    }
  }

  private preencherForm(usuario: Usuario): void {
    this.registerForm.patchValue({
      id: usuario.id, 
      nome: usuario.nome,
      email: usuario.email,
      telefone: usuario.telefone,
      senha: ''
    })
  }

  private preencheUsuario(): Usuario {
    const moradorForm = this.registerForm.value

    const usuario = new Usuario()
    usuario.id = moradorForm['id']
    usuario.nome = moradorForm['nome']
    usuario.email = moradorForm['email']
    usuario.senha = moradorForm['senha']
    usuario.telefone = moradorForm['telefone']

    return usuario
  }

  private salvaUsuario() {
    const usuario = this.preencheUsuario()
    this.usuarioService.salvaUsuario(usuario).subscribe(() => {
      this.limpaForm()
    })
  }

  onReset() {
    this.submitted = false
    this.registerForm.reset()
  }

  private limpaForm() {
    this.onReset()
    this.route.navigate(['/configuracoes/usuario'])
  }

}

function ValidaSenha() {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls['id']
    const matchingControl = formGroup.controls['senha']

    if (matchingControl.errors && !matchingControl.errors.mustMatch) return

    if (!control.value && !matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true })
    } else {
      matchingControl.setErrors(null)
    }
  }
}
