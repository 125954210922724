import { Component, OnDestroy, OnInit, } from '@angular/core';
import { Morador } from 'src/app/models/morador';
import { MoradorService } from 'src/app/services/morador.service';

@Component({
  selector: 'morador',
  templateUrl: 'morador.template.html'
})
export class MoradorComponent implements OnDestroy, OnInit  {

  public nav:any;
  public moradores: Morador[]

  dtOptions: DataTables.Settings = {}

  public constructor(private readonly moradorService: MoradorService) {
    this.nav = document.querySelector('nav.navbar');
  }

  public ngOnInit():any {
    this.nav.className += " white-bg";
    const response = this.moradorService.buscaMoradores()
    response.subscribe(obs => this.moradores = obs.content.sort((a,b) => {
      if(a.nome.toLowerCase > b.nome.toLowerCase) return 1
      else if(a.nome.toLowerCase < b.nome.toLowerCase) return -1
      return 0
    }))
  }

  public ngOnDestroy():any {
    this.nav.classList.remove("white-bg");
  }

  public removeMorador(idMorador: string): void {
    const index = this.moradores.findIndex( i => i.id === idMorador)
    const response = this.moradorService.removeMorador(idMorador)
    response.subscribe(obs => this.moradores.splice(index, 1))
  }

}
