import { Component, OnDestroy, OnInit, } from '@angular/core';
import { ContasReceber } from 'src/app/models/contasReceber';
import { ContasReceberService } from 'src/app/services/contasReceber.service';

@Component({
  selector: 'contasreceber',
  templateUrl: 'contasreceber.template.html'
})
export class ContasReceberComponent implements OnDestroy, OnInit  {

  public nav:any;
  public contas: ContasReceber[]

  public constructor(private readonly contasReceberService: ContasReceberService) {
    this.nav = document.querySelector('nav.navbar');
  }

  public ngOnInit():any {
    this.nav.className += " white-bg";
    const response = this.contasReceberService.buscaContasReceber()
    response.subscribe(obs => this.contas = obs.content)
  }

  public ngOnDestroy():any {
    this.nav.classList.remove("white-bg");
  }

  public removeContaReceber(idContaReceber: string): void {
    const index = this.contas.findIndex( i => i.id === idContaReceber)
    const response = this.contasReceberService.removeContasReceber(idContaReceber)
    response.subscribe(obs => this.contas.splice(index, 1))
  }

}
