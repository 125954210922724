export class ContasPagar {
    id: string
    numero: string
    digito: string
    nome: string
    historico: string
    dataEmissao: string
    dataVencimento: string
    dataPagamento: string
    especiePagamento: string
    valor: number
    status: string
    idFornecedor: string
    idAssociacao: string
}