import { Perfil } from "./perfil"

export class Usuario {
    id: number
    senha: string
    nome: string
    email: string
    telefone: string
    perfil: Perfil[] = []
    idAssociacao: string
    token?: string
}
