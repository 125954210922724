import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {RouterModule} from "@angular/router";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UsuarioComponent } from "./usuario.component";
import { CadastroUsuarioComponent } from "./cadastro.component";
import { NgxMaskModule } from 'ngx-mask'

@NgModule({
  declarations: [
    UsuarioComponent,
    CadastroUsuarioComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [
    UsuarioComponent
  ]
})

export class UsuarioModule {}
