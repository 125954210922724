import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: 'root' })
export class DashboardService {

    constructor(private readonly http: HttpClient) { }

    carregaDashboard(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/dashboard`)
        .pipe(
            tap(response => {
            return response
        } ))
    }

}