import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {RouterModule} from "@angular/router";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ContasPagarComponent } from "./contaspagar.component";
import { CadastroContaPagarComponent } from "./cadastro.component"

import { NgxMaskModule } from 'ngx-mask'
import { NgxCurrencyModule, CurrencyMaskInputMode } from 'ngx-currency'

const customCurrencyMaskConfig = {
  align: "left",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};

@NgModule({
  declarations: [
    ContasPagarComponent,
    CadastroContaPagarComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxMaskModule.forRoot(),
  ],
  exports: [
    ContasPagarComponent
  ]
})

export class ContasPagarModule {}
