import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {RouterModule} from "@angular/router";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ComercioComponent } from "./comercio.component";
import { CadastroComercioComponent } from "./cadastro.component"

import { NgxMaskModule } from 'ngx-mask'

@NgModule({
  declarations: [
    ComercioComponent,
    CadastroComercioComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [
    ComercioComponent
  ]
})

export class ComercioModule {}
