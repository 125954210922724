import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { tap } from "rxjs/operators"
import { environment } from "src/environments/environment"
import { CategoriaContasReceber } from "../models/categoriaContasReceber"

@Injectable({ providedIn: 'root' })
export class CategoriaContasReceberService {

    constructor(private readonly http: HttpClient) { }

    buscaCategorias(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/categoria-contas-receber/0/1000`)
        .pipe(
            tap(response => {
            return response
        } ))
    }

    buscaCategoriaPorId(id: string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/categoria-contas-receber/${id}`)
        .pipe(
            tap(response => {
            return response
        } ))
    }

    salvaCategoria(categoria: CategoriaContasReceber): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/categoria-contas-receber`, categoria)
        .pipe(
            tap(response => {
                return response
            })
        )
    }

    removeCategoria(idFornecedor: string): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/categoria-contas-receber/${idFornecedor}`)
        .pipe(
            tap(response => {
                return response
            })
        )
    }
}