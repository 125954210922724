import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { environment } from '../../environments/environment';
import { CadastroMoradorRequest } from "../models/CadastroMoradorRequest";
import { Morador } from "../models/morador";

@Injectable({ providedIn: 'root' })
export class MoradorService {

    constructor(private readonly http: HttpClient) { }

    buscaMoradores(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/morador/0/1000`)
        .pipe(
            tap(response => {
            return response
        } ))
    }

    buscaMoradorPorId(id: string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/morador/${id}`)
        .pipe(
            tap(response => {
            return response
        } ))
    }

    salvaMorador(requestMorador: CadastroMoradorRequest): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/morador`, requestMorador)
        .pipe(
            tap(response => {
                return response
            })
        )
    }

    removeMorador(idMorador: string): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/morador/${idMorador}`)
        .pipe(
            tap(response => {
                return response
            })
        )
    }
}