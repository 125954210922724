import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { tap } from "rxjs/operators"
import { environment } from "src/environments/environment"
import { ContasPagar } from "../models/contasPagar"

@Injectable({ providedIn: 'root' })
export class ContasPagarService {

    constructor(private readonly http: HttpClient) { }

    buscaContasPagar(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/conta-pagar/0/1000`)
        .pipe(
            tap(response => {
            return response
        } ))
    }

    buscaContasPagarPorId(id: string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/conta-pagar/${id}`)
        .pipe(
            tap(response => {
            return response
        } ))
    }

    salvaContasPagar(contasPagar: ContasPagar): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/conta-pagar`, contasPagar)
        .pipe(
            tap(response => {
                return response
            })
        )
    }

    removeContasPagar(idContasPagar: string): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/conta-pagar/${idContasPagar}`)
        .pipe(
            tap(response => {
                return response
            })
        )
    }
}