import { Component, OnDestroy, OnInit, } from '@angular/core';
import { PerfilService } from 'src/app/services/perfil.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Perfil } from 'src/app/models/perfil';
import { Usuario } from 'src/app/models/usuario';

@Component({
  selector: 'usuario',
  templateUrl: 'usuario.template.html'
})
export class UsuarioComponent implements OnDestroy, OnInit  {

  public nav:any;
  public perfilList: Perfil[]
  public usuarios: Usuario[]

  public constructor(private readonly perfilService: PerfilService, 
    private readonly usuarioService: UsuarioService) {

    this.nav = document.querySelector('nav.navbar');
  }

  public ngOnInit():any {
    this.nav.className += " white-bg";
    this.buscarUsuario()
  }

  public ngOnDestroy():any {
    this.nav.classList.remove("white-bg");
  }

  private buscarUsuario():void {
    const response = this.usuarioService.buscaUsuarios()
    response.subscribe(obs => this.usuarios = obs.content.sort((a,b) => {
      if(a.nome.toLowerCase > b.nome.toLowerCase) return 1
      else if(a.nome.toLowerCase < b.nome.toLowerCase) return -1
      return 0
    }))

  }

  private buscarPerfil():void {
    const response = this.perfilService.buscaPerfis()
    response.subscribe(obs => this.perfilList = obs.content)
  }

}
