import { Component, OnDestroy, OnInit, } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Segmento } from 'src/app/models/segmento';
import { SegmentoService } from 'src/app/services/segmento.service';

@Component({
  selector: 'cadastrosegmento',
  templateUrl: 'cadastro.template.html'
})
export class CadastroSegmentoComponent implements OnDestroy, OnInit  {

  public registerForm: FormGroup
  public submitted = false
  public idSegmento: string

  public nav:any;

  public constructor(
    private readonly segmentoService: SegmentoService,
    private readonly activeRoute: ActivatedRoute,
    private readonly route: Router,
    private formBuilder: FormBuilder
  ) {
    this.nav = document.querySelector('nav.navbar');
    this.activeRoute.params.subscribe(params => this.idSegmento = params.id);
  }

  public ngOnInit():any {
    this.nav.className += " white-bg";

    this.registerForm = this.formBuilder.group({
      id: null,
      nome: [null, Validators.required],
    })

    if (this.idSegmento) {
      const response = this.segmentoService.buscaSegmentoPorId(this.idSegmento)
      response.subscribe(obs => {
        this.preencheForm(obs)
      })
    }
  }

  get f() { return this.registerForm.controls }

  public ngOnDestroy():any {
    this.nav.classList.remove("white-bg");
  }

  onSubmit() {
    this.submitted = true
    if (this.registerForm.invalid) return
    this.salvaSegmento()
  }

  onReset() {
    this.submitted = false
    this.registerForm.reset()
  }

  private preencheForm(segmento: Segmento): void {
    this.registerForm.patchValue({
      id: segmento.id,
      nome: segmento.nome,
    })
  }

  private preencheSegmento(): Segmento {
    const moradorForm = this.registerForm.value

    const segmento = new Segmento()
    segmento.id = moradorForm['id']
    segmento.nome = moradorForm['nome']
    return segmento
  }

  private salvaSegmento() {
    const segmento = this.preencheSegmento()
    this.segmentoService.salvaSegmento(segmento).subscribe(() => {
      this.limpaForm()
    })
  }

  private limpaForm() {
    this.onReset()
    this.route.navigate(['/configuracoes/segmento'])
  }

}
