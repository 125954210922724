import { Component, OnDestroy, OnInit, } from '@angular/core';
import { CategoriaContasReceber } from 'src/app/models/categoriaContasReceber';
import { CategoriaContasReceberService } from 'src/app/services/categoriaContasReceber.service';

@Component({
  selector: 'categoria',
  templateUrl: 'categoria.template.html'
})
export class CategoriaComponent implements OnDestroy, OnInit  {

  public nav:any;
  public categorias: CategoriaContasReceber[]

  public constructor(private readonly categoriaService: CategoriaContasReceberService) {
    this.nav = document.querySelector('nav.navbar');
  }

  public ngOnInit():any {
    this.nav.className += " white-bg";
    const response = this.categoriaService.buscaCategorias()
    response.subscribe(obs => this.categorias = obs.content)
  }

  public ngOnDestroy():any {
    this.nav.classList.remove("white-bg");
  }

  public removeCategoria(idCategoria: string): void {
    const index = this.categorias.findIndex( i => i.id === idCategoria)
    const response = this.categoriaService.removeCategoria(idCategoria)
    response.subscribe(obs => this.categorias.splice(index, 1))
  }

}
