import { Component, OnDestroy, OnInit, } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Fornecedor } from 'src/app/models/fornecedor';
import { ContasPagar } from 'src/app/models/contasPagar';
import { ContasPagarService } from 'src/app/services/contasPagar.service';
import { FornecedorService } from 'src/app/services/fornecedor.service';

@Component({
  selector: 'cadastrocontapagar',
  templateUrl: 'cadastro.template.html'
})
export class CadastroContaPagarComponent implements OnDestroy, OnInit  {

  public registerForm: FormGroup
  public submitted = false
  public idContasPagar: string
  public fornecedores: Fornecedor[]
  public fornecedorSelecionado: Fornecedor = new Fornecedor()

  public nav:any;

  public constructor(
    private readonly fornecedorService: FornecedorService,
    private readonly contasPagarService: ContasPagarService,
    private readonly activeRoute: ActivatedRoute,
    private readonly route: Router,
    private formBuilder: FormBuilder
  ) {
    this.nav = document.querySelector('nav.navbar');
    this.activeRoute.params.subscribe(params => this.idContasPagar = params.id);
  }

  get f() { return this.registerForm.controls }

  public ngOnInit():any {
    this.nav.className += " white-bg";

    this.carregaFornecedores()

    this.registerForm = this.formBuilder.group({
      id: null,
      numero: [null, Validators.required],
      digito: null,
      nome: [null, Validators.required],
      historico: null,
      dataEmissao: [null, Validators.required],
      dataVencimento: [null, Validators.required],
      dataPagamento: null,
      especiePagamento: "0",
      valor: [0.0, Validators.min(0.1)],
      status: "PENDENTE",
      idFornecedor: ["0", Validators.minLength(2)],
    }, {
      validator: [ValidaData('dataEmissao'), ValidaData('dataVencimento'), ValidaData('dataPagamento')]
    })

    if (this.idContasPagar) {
      const response = this.contasPagarService.buscaContasPagarPorId(this.idContasPagar)
      response.subscribe(obs => this.preencheForm(obs))
    }
  }

  ngOnDestroy():any {
    this.nav.classList.remove("white-bg");
  }

  onSubmit() {
    this.submitted = true
    if (this.registerForm.invalid) return
    this.salvaContasPagar()
  }

  onReset() {
    this.submitted = false
    this.registerForm.reset()
  }

  exibeDadosFornecedor(idSelecionado: string) {
    if(idSelecionado !== "0") {
      this.fornecedorSelecionado = this.fornecedores.find(fornecedor => fornecedor.id === idSelecionado)
    } else {
      const fornecedorVazio = new Fornecedor()
      fornecedorVazio.email = ""
      fornecedorVazio.celular = ""
      fornecedorVazio.telefone = ""
      this.fornecedorSelecionado = fornecedorVazio
    }
  }

  private salvaContasPagar() {
    const contasPagar = this.preencheContasPagar()
    this.contasPagarService.salvaContasPagar(contasPagar).subscribe(() => {
      this.limpaForm()
    })
  }

  private preencheForm(contasPagar: ContasPagar): void {
    this.registerForm.patchValue({
      id: contasPagar.id,
      numero: contasPagar.numero,
      digito: contasPagar.digito,
      nome: contasPagar.nome,
      historico: contasPagar.historico,
      dataEmissao: contasPagar.dataEmissao,
      dataVencimento: contasPagar.dataVencimento,
      dataPagamento: contasPagar.dataPagamento,
      especiePagamento: this.buscaChaveEspecie(contasPagar.especiePagamento),
      valor: contasPagar.valor,
      status: contasPagar.status,
      idFornecedor: contasPagar.idFornecedor,
    })

    this.fornecedorService.buscaFornecedorPorId(contasPagar.idFornecedor)
    .subscribe(obs => this.fornecedorSelecionado = obs)
  }

  private preencheContasPagar(): ContasPagar {
    const contasPagarForm = this.registerForm.value

    const contasPagar = new ContasPagar()

    contasPagar.id = contasPagarForm['id']
    contasPagar.numero = contasPagarForm['numero']
    contasPagar.digito = contasPagarForm['digito']
    contasPagar.nome = contasPagarForm['nome']
    contasPagar.historico = contasPagarForm['historico']
    contasPagar.dataEmissao = contasPagarForm['dataEmissao']
    contasPagar.dataVencimento = contasPagarForm['dataVencimento']
    contasPagar.dataPagamento = contasPagarForm['dataPagamento']
    contasPagar.especiePagamento = this.buscaValorEspecie(contasPagarForm['especiePagamento'])
    contasPagar.valor = contasPagarForm['valor']
    contasPagar.status = contasPagarForm['status']
    contasPagar.idFornecedor = contasPagarForm['idFornecedor']

    return contasPagar
  }

  private limpaForm() {
    this.onReset()
    this.route.navigate(['/financeiro/contaspagar'])
  }

  private carregaFornecedores() {
    const response = this.fornecedorService.buscaFornecedores()
    response.subscribe(obs => {
      this.fornecedores = obs.content

      const fornecedor = new Fornecedor()
      fornecedor.id = "0"
      fornecedor.nome = "Fornecedor"
      this.fornecedores.unshift(fornecedor)
    })
  }

  private buscaValorEspecie(chaveEspecie: string): string {
    const tabelaUnidade = new Map<string, string>()
    tabelaUnidade.set("1", "DINHEIRO")
    tabelaUnidade.set("2", "DEBITO")
    tabelaUnidade.set("3", "CREDITO")
    tabelaUnidade.set("4", "CHEQUE")
    tabelaUnidade.set("5", "PIX")
    return tabelaUnidade.get(chaveEspecie)
  }

  private buscaChaveEspecie(valorEspecie: string): string {
    if(valorEspecie) {
      const tabelaUnidade = new Map<string, string>()
      tabelaUnidade.set("DINHEIRO", "1")
      tabelaUnidade.set("DEBITO", "2")
      tabelaUnidade.set("CREDITO", "3")
      tabelaUnidade.set("CHEQUE", "4")
      tabelaUnidade.set("PIX", "5")
      return tabelaUnidade.get(valorEspecie)
    }

    return "0"
  }

}

function ValidaData(campoParaValidar: string) {
  return (formGroup: FormGroup) => {
    const matchingControl = formGroup.controls[campoParaValidar]

    if (matchingControl.value) {
      if (matchingControl.errors && !matchingControl.errors.mustMatch) return

      const data = matchingControl.value

      if (data.length === 10 && moment(data, 'DD/MM/YYYY').isValid()) {
        matchingControl.setErrors(null)
      } else if (data.length === 10) {
        matchingControl.setErrors({ mustMatch: true })
      }

    }
  }
}
