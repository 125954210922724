import { Routes } from "@angular/router"
import { BasicLayoutComponent } from "./components/common/layouts/basicLayout.component"
import { BlankLayoutComponent } from "./components/common/layouts/blankLayout.component"
import { AuthGuard } from './helpers';
import { LoginComponent } from "./views/appviews/login.component"
import { DashboardComponent } from "./views/dashboards/dashboard.component"
import { AluguelComponent } from './views/aluguel/aluguel.component'
import { CategoriaComponent } from './views/categoria/categoria.component'
import { ComercioComponent } from './views/comercio/comercio.component'
import { ContasPagarComponent } from './views/contaspagar/contaspagar.component'
import { ContasReceberComponent } from './views/contasreceber/contasreceber.component'
import { FluxoCaixaComponent } from './views/fluxocaixa/fluxocaixa.component'
import { FornecedorComponent } from './views/fornecedor/fornecedor.component'
import { InformaisComponent } from './views/informais/informais.component'
import { MoradorComponent } from './views/morador/morador.component'
import { CadastroMoradorComponent } from './views/morador/cadastro.component'
import { OcorrenciaComponent } from './views/ocorrencia/ocorrencia.component'
import { CadastroOcorrenciaComponent } from './views/ocorrencia/cadastro.component'
import { CadastroComercioComponent } from './views/comercio/cadastro.component'
import { CadastroFornecedorComponent } from './views/fornecedor/cadastro.component'
import { CadastroRecursoComponent } from './views/recurso/cadastro.component'
import { CadastroAluguelComponent } from './views/aluguel/cadastro.component'
import { CadastroInformaisComponent } from './views/informais/cadastro.component'
import { CadastroContaPagarComponent } from './views/contaspagar/cadastro.component'
import { CadastroContasReceberComponent } from './views/contasreceber/cadastro.component'
import { CadastroSegmentoComponent } from './views/segmento/cadastro.component'
import { CadastroCategoriaComponent } from './views/categoria/cadastro.component'
import { CadastroUsuarioComponent } from './views/usuario/cadastro.component'
import { CadastroPerfilComponent } from './views/perfil/cadastro.template'
import { PerfilComponent } from './views/perfil/perfil.component'
import { RecursoComponent } from './views/recurso/recurso.component'
import { SegmentoComponent } from './views/segmento/segmento.component'
import { UsuarioComponent } from './views/usuario/usuario.component'


export const ROUTES:Routes = [
  // Main redirect
  {path: '', redirectTo: 'dashboard', pathMatch: 'full', canActivate: [AuthGuard]},

  //{path: '', redirectTo: 'login', pathMatch: 'full'},

  //App views
  {
    path: '', component: BlankLayoutComponent,
    children: [
      { path: 'login', component: LoginComponent },
    ]
  },

  {
    path: '', component: BasicLayoutComponent, canActivate: [AuthGuard],
    children: [
      {path: 'dashboard', component: DashboardComponent},
    ]
  },
  {
    path: '', component: BasicLayoutComponent, canActivate: [AuthGuard],
    children: [
      {path: 'ocorrencia', component: OcorrenciaComponent},
      {path: 'ocorrencia/cadastro', component: CadastroOcorrenciaComponent},
      {path: 'ocorrencia/cadastro/:id', component: CadastroOcorrenciaComponent},
    ]
  },
  {
    path: 'cadastros', component: BasicLayoutComponent, canActivate: [AuthGuard],
    children: [
      { path: 'morador', component: MoradorComponent },
      { path: 'morador/cadastro', component: CadastroMoradorComponent },
      { path: 'morador/cadastro/:id', component: CadastroMoradorComponent },
      { path: 'comercio', component: ComercioComponent },
      { path: 'comercio/cadastro', component: CadastroComercioComponent },
      { path: 'comercio/cadastro/:id', component: CadastroComercioComponent },
      { path: 'fornecedor', component: FornecedorComponent },
      { path: 'fornecedor/cadastro', component: CadastroFornecedorComponent },
      { path: 'fornecedor/cadastro/:id', component: CadastroFornecedorComponent },
      { path: 'recurso', component: RecursoComponent },
      { path: 'recurso/cadastro', component: CadastroRecursoComponent },
      { path: 'recurso/cadastro/:id', component: CadastroRecursoComponent },
    ]
  },
  {
    path: 'faturamento', component: BasicLayoutComponent, canActivate: [AuthGuard],
    children: [
      { path: 'aluguel', component: AluguelComponent },
      { path: 'aluguel/cadastro', component: CadastroAluguelComponent },
      { path: 'informais', component: InformaisComponent },
      { path: 'informais/cadastro', component: CadastroInformaisComponent },
    ]
  },
  {
    path: 'financeiro', component: BasicLayoutComponent, canActivate: [AuthGuard],
    children: [
      { path: 'fluxocaixa', component: FluxoCaixaComponent },
      { path: 'contaspagar', component: ContasPagarComponent },
      { path: 'contaspagar/cadastro', component: CadastroContaPagarComponent },
      { path: 'contaspagar/cadastro/:id', component: CadastroContaPagarComponent },
      { path: 'contasreceber', component: ContasReceberComponent },
      { path: 'contasreceber/cadastro', component: CadastroContasReceberComponent },
      { path: 'contasreceber/cadastro/:id', component: CadastroContasReceberComponent },
    ]
  },
  {
    path: 'configuracoes', component: BasicLayoutComponent, canActivate: [AuthGuard],
    children: [
      { path: 'segmento', component: SegmentoComponent },
      { path: 'segmento/cadastro', component: CadastroSegmentoComponent },
      { path: 'segmento/cadastro/:id', component: CadastroSegmentoComponent },
      { path: 'categoria', component: CategoriaComponent },
      { path: 'categoria/cadastro', component: CadastroCategoriaComponent },
      { path: 'categoria/cadastro/:id', component: CadastroCategoriaComponent },
      { path: 'usuario', component: UsuarioComponent },
      { path: 'usuario/cadastro', component: CadastroUsuarioComponent },
      { path: 'usuario/cadastro/:id', component: CadastroUsuarioComponent },
      { path: 'perfil', component: PerfilComponent },
      { path: 'perfil/cadastro', component: CadastroPerfilComponent },
    ]
  },

  // Handle all other routes
  {path: '**',  redirectTo: 'dashboard'}
];
