import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Comercio } from "../models/comercio";

@Injectable({ providedIn: 'root' })
export class ComercioService {

    constructor(private readonly http: HttpClient) { }

    buscaComercios(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/comercio/0/1000`)
        .pipe(
            tap(response => {
            return response
        } ))
    }

    buscaComercioPorId(id: string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/comercio/${id}`)
        .pipe(
            tap(response => {
            return response
        } ))
    }

    salvaComercio(comercio: Comercio): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/comercio`, comercio)
        .pipe(
            tap(response => {
                return response
            })
        )
    }

    removeComercio(idComercio: string): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/comercio/${idComercio}`)
        .pipe(
            tap(response => {
                return response
            })
        )
    }

}