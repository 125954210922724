import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {RouterModule} from "@angular/router";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PerfilComponent } from "./perfil.component";
import { CadastroPerfilComponent } from "./cadastro.template";

@NgModule({
  declarations: [
    PerfilComponent,
    CadastroPerfilComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    PerfilComponent
  ]
})

export class PerfilModule {}
