import { Component, OnDestroy, OnInit, } from '@angular/core';
import { Segmento } from 'src/app/models/segmento';
import { SegmentoService } from 'src/app/services/segmento.service';

@Component({
  selector: 'segmento',
  templateUrl: 'segmento.template.html'
})
export class SegmentoComponent implements OnDestroy, OnInit  {

  public nav:any;
  public segmentos: Segmento[]

  public constructor(private readonly segmentoService: SegmentoService) {
    this.nav = document.querySelector('nav.navbar');
  }

  public ngOnInit():any {
    this.nav.className += " white-bg";
    const response = this.segmentoService.buscaSegmentos()
    response.subscribe(obs => this.segmentos = obs.content.sort((a,b) => {
      if(a.nome.toLowerCase > b.nome.toLowerCase) return 1
      else if(a.nome.toLowerCase < b.nome.toLowerCase) return -1
      return 0
    }))
  }

  public ngOnDestroy():any {
    this.nav.classList.remove("white-bg");
  }

  public removeSegmento(idSegmento: string): void {
    const index = this.segmentos.findIndex( i => i.id === idSegmento)
    const response = this.segmentoService.removeSegmento(idSegmento)
    response.subscribe(obs => this.segmentos.splice(index, 1))
  }

}
