import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Fornecedor } from "../models/fornecedor";

@Injectable({ providedIn: 'root' })
export class FornecedorService {

    constructor(private readonly http: HttpClient) { }

    buscaFornecedores(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/fornecedor/0/1000`)
        .pipe(
            tap(response => {
            return response
        } ))
    }

    buscaFornecedorPorId(id: string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/fornecedor/${id}`)
        .pipe(
            tap(response => {
            return response
        } ))
    }

    salvaFornecedor(fornecedor: Fornecedor): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/fornecedor`, fornecedor)
        .pipe(
            tap(response => {
                return response
            })
        )
    }

    removeFornecedor(idFornecedor: string): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/fornecedor/${idFornecedor}`)
        .pipe(
            tap(response => {
                return response
            })
        )
    }
}