export class Ocorrencia {
    id: string
    local: string
    pontoReferencia: string
    dataInicio: string
    dataFim: string
    prioridade: string
    titulo: string
    descricao: string
    status: string = "PENDENTE"
    idMorador: string
    nomeMorador: string
    idAssociacao: string
}