import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class PerfilService {

    constructor(private readonly http: HttpClient) { }

    buscaPerfis(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/perfil`)
        .pipe(
            tap(response => {
            return response
        } ))
    }
    
}