import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {RouterModule} from "@angular/router";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FornecedorComponent } from "./fornecedor.component";
import { CadastroFornecedorComponent } from "./cadastro.component";

import { NgxMaskModule } from 'ngx-mask'

@NgModule({
  declarations: [
    FornecedorComponent,
    CadastroFornecedorComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [
    FornecedorComponent
  ]
})

export class FornecedorModule {}
