import { Component, OnDestroy, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import 'jquery-slimscroll';
import { Usuario } from 'src/app/models/usuario';
import { AuthenticationService } from 'src/app/services/authentication.service';

declare var jQuery:any;

@Component({
  selector: 'navigation',
  templateUrl: 'navigation.template.html'
})

export class NavigationComponent implements OnDestroy, OnInit {

  public usuario: Usuario

  constructor(private router: Router,
    private authenticationService: AuthenticationService) {}

  ngAfterViewInit() {
    jQuery('#side-menu').metisMenu();

    if (jQuery("body").hasClass('fixed-sidebar')) {
      jQuery('.sidebar-collapse').slimscroll({
        height: '100%'
      })
    }
  }

  activeRoute(routename: string): boolean{
    return this.router.url.indexOf(routename) > -1;
  }

  sair() {
    this.authenticationService.logout()
  }

  public ngOnInit():any {
    this.usuario = this.authenticationService.currentUserValue
  }


  public ngOnDestroy():any {
  }


}
