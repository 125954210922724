import { Endereco } from "./endereco"
import { Segmento } from "./segmento"

export class Comercio {
    id: string
    nomeFantasia: string
    razaoSocial: string
    inicioAtividade: string
    cnpj: string
    ie: string
    telefone: string
    celular: string
    email: string
    observacao: string
    dataCadastro: string
    dataAssociado: string
    responsavel: string
    associado: boolean
    diaPagamento: string
    valorMensalidade: number
    idSegmento: string
    segmento: Segmento = new Segmento()
    endereco: Endereco = new Endereco()
    idAssociacao: string
}