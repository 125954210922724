import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";

import {DashboardComponent} from "./dashboard.component";

// Chart.js Angular 2 Directive by Valor Software (npm)
import { ChartsModule } from 'ng2-charts/ng2-charts';

import { FlotModule } from '../../components/charts/flotChart';
import { IboxtoolsModule } from '../../components/common/iboxtools/iboxtools.module';
import { PeityModule } from '../../components/charts/peity';
import { SparklineModule } from '../../components/charts/sparkline';
import { JVectorMapModule } from '../../components/map/jvectorMap';
import { RouterModule } from "@angular/router";

import { NgxMaskModule } from 'ngx-mask'
import { NgxCurrencyModule, CurrencyMaskInputMode } from 'ngx-currency'

const customCurrencyMaskConfig = {
  align: "left",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};


@NgModule({
  declarations: [DashboardComponent],
  imports: [
    BrowserModule,
    RouterModule,
    ChartsModule, 
    FlotModule,
    IboxtoolsModule,
    PeityModule,
    SparklineModule,
    JVectorMapModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxMaskModule.forRoot(),
  ],
  exports: [DashboardComponent],
})

export class DashboardsModule {}
