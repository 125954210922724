import { Component, OnDestroy, OnInit, } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'starter',
  templateUrl: 'starter.template.html'
})
export class StarterViewComponent implements OnDestroy, OnInit  {

  public nav:any;

  public constructor(private authenticationService: AuthenticationService) {
    this.nav = document.querySelector('nav.navbar');
  }

  public ngOnInit():any {
    this.nav.className += " white-bg";
  }

  public ngOnDestroy():any {
    this.nav.classList.remove("white-bg");
  }

}
