export class ContasReceber {
    id: string
    numero: string
    digito: string
    nome: string
    historico: string
    dataEmissao: string
    dataVencimento: string
    dataPagamento: string
    especiePagamento: string
    valor: number
    status: string
    categoria: string = "ASSOCIADO"
    idOrigem: string
    idAssociacao: string
}