import { Component, OnDestroy, OnInit, } from '@angular/core';
import { Ocorrencia } from 'src/app/models/ocorrencia';
import { OcorrenciaService } from 'src/app/services/ocorrencia.service';

@Component({
  selector: 'ocorrencia',
  templateUrl: 'ocorrencia.template.html'
})
export class OcorrenciaComponent implements OnDestroy, OnInit  {

  public nav:any;
  public ocorrencias: Ocorrencia[]

  public constructor(private readonly ocorrenciaService: OcorrenciaService) {
    this.nav = document.querySelector('nav.navbar');
    const response = this.ocorrenciaService.buscaOcorrencias()
    response.subscribe(obs => this.ocorrencias = obs.content)
  }

  public ngOnInit():any {
    this.nav.className += " white-bg";
  }

  public ngOnDestroy():any {
    this.nav.classList.remove("white-bg");
  }

  public removeOcorrencia(idOcorrencia: string): void {
    const index = this.ocorrencias.findIndex( i => i.id === idOcorrencia)
    const response = this.ocorrenciaService.removeOcorrencia(idOcorrencia)
    response.subscribe(obs => this.ocorrencias.splice(index, 1))
  }

}
