import { Comercio } from "./comercio"
import { Endereco } from "./endereco"

export class Morador {
    id: string
    nome: string
    dataNascimento: string
    dataAssociado: string
    inicioMoradia: number
    cpf: string
    telefoneResidencial: string
    telefoneCelular: string
    email: string
    associado: boolean
    diaPagamento: number
    valorMensalidade: number
    idAssociacao: string
    ehComerciante: boolean
    endereco: Endereco = new Endereco()
    idComercio?: Comercio = null
}