import { Component, OnDestroy, OnInit, } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Comercio } from 'src/app/models/comercio';
import { Segmento } from 'src/app/models/segmento';
import { ComercioService } from 'src/app/services/comercio.service';
import { SegmentoService } from 'src/app/services/segmento.service';

@Component({
  selector: 'cadastrocomercio',
  templateUrl: 'cadastro.template.html'
})
export class CadastroComercioComponent implements OnDestroy, OnInit  {

  public registerForm: FormGroup
  public submitted = false
  public idComercio: string
  public segmentos: Segmento[]

  public nav:any;

  public constructor(
    private readonly comercioService: ComercioService,
    private readonly segmentoService: SegmentoService,
    private readonly activeRoute: ActivatedRoute,
    private readonly route: Router,
    private formBuilder: FormBuilder
  ) {
    this.nav = document.querySelector('nav.navbar');
    this.activeRoute.params.subscribe(params => this.idComercio = params.id);
  }

  get f() { return this.registerForm.controls }

  public ngOnInit():any {
    this.nav.className += " white-bg";

    this.carregaSegmentos()

    this.registerForm = this.formBuilder.group({
      id: null,
      nomeFantasia: [null, Validators.required],
      razaoSocial: null,
      inicioAtividade: null,
      cnpj: null,
      ie: null,
      telefone: null,
      celular: [null, Validators.required],
      email: [null, Validators.email],
      observacao: null,
      dataCadastro: null,
      dataAssociado: null,
      responsavel: null,
      associado: false,
      diaPagamento: null,
      valorMensalidade: null,
      idSegmento: ["0", Validators.minLength(2)],
      complemento: null,
      rua: null,
      numero: null,
      cep: null,
      bairro: null,
      cidade: null,
    }, {
      validator: [ValidaVencimento(), ValidaData('dataCadastro'), ValidaData('dataAssociado'), ValidaData('inicioAtividade')]
    })

    if (this.idComercio) {
      const response = this.comercioService.buscaComercioPorId(this.idComercio)
      response.subscribe(obs => {
        this.preencheForm(obs)
      })
    }
  }

  public ngOnDestroy():any {
    this.nav.classList.remove("white-bg");
  }

  onSubmit() {
    this.submitted = true
    if (this.registerForm.invalid) return
    this.salvaComercio()
  }

  onReset() {
    this.submitted = false
    this.registerForm.reset()
  }

  private carregaSegmentos() {
    const response = this.segmentoService.buscaSegmentos()
    response.subscribe(obs => {
      this.segmentos = obs.content

      const segmento = new Segmento()
      segmento.id = "0"
      segmento.nome = "Selecione"
      this.segmentos.unshift(segmento)
    })
  }

  private preencheForm(comercio: Comercio): void {
    this.registerForm.patchValue({
      id: comercio.id,
      nomeFantasia: comercio.nomeFantasia,
      razaoSocial: comercio.razaoSocial,
      inicioAtividade: comercio.inicioAtividade,
      cnpj: comercio.cnpj,
      ie: comercio.ie,
      telefone: comercio.telefone,
      celular: comercio.celular,
      email: comercio.email,
      observacao: comercio.observacao,
      dataCadastro: comercio.dataCadastro,
      dataAssociado: comercio.dataAssociado,
      responsavel: comercio.responsavel,
      associado: comercio.associado,
      diaPagamento: comercio.diaPagamento,
      valorMensalidade: comercio.valorMensalidade,
      idSegmento: comercio.segmento.id,
      complemento: comercio.endereco.complemento,
      rua: comercio.endereco.rua,
      numero: comercio.endereco.numero,
      cep: comercio.endereco.cep,
      bairro: comercio.endereco.bairro,
      cidade: comercio.endereco.cidade,
    })
  }

  private preencheComercio(): Comercio {
    const comercioForm = this.registerForm.value

    const comercio = new Comercio()

    comercio.id = comercioForm['id']
    comercio.nomeFantasia = comercioForm['nomeFantasia']
    comercio.razaoSocial = comercioForm['razaoSocial']
    comercio.inicioAtividade = comercioForm['inicioAtividade']
    comercio.cnpj = comercioForm['cnpj']
    comercio.ie = comercioForm['ie']
    comercio.telefone = comercioForm['telefone']
    comercio.celular = comercioForm['celular']
    comercio.email = comercioForm['email']
    comercio.observacao = comercioForm['observacao']
    comercio.dataCadastro = comercioForm['dataCadastro']
    comercio.dataAssociado = comercioForm['dataAssociado']
    comercio.responsavel = comercioForm['responsavel']
    comercio.associado = comercioForm['associado']
    comercio.diaPagamento = comercioForm['diaPagamento']
    comercio.valorMensalidade = comercioForm['valorMensalidade']
    const idSegmento = comercioForm['idSegmento']
    comercio.segmento = this.segmentos.find(segmento => segmento.id === idSegmento)
    comercio.endereco.complemento = comercioForm['complemento']
    comercio.endereco.rua = comercioForm['rua']
    comercio.endereco.numero = comercioForm['numero']
    comercio.endereco.cep = comercioForm['cep']
    comercio.endereco.bairro = comercioForm['bairro']
    comercio.endereco.cidade = comercioForm['cidade']

    return comercio
  }

  private salvaComercio() {
    const comercio = this.preencheComercio()
    this.comercioService.salvaComercio(comercio).subscribe(() => {
      this.limpaForm()
    })
  }

  private limpaForm() {
    this.onReset()
    this.route.navigate(['/cadastros/comercio'])
  }
}

function ValidaVencimento() {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls['associado']
    const matchingControl = formGroup.controls['diaPagamento']

    if (matchingControl.errors && !matchingControl.errors.mustMatch) return

    if (control.value && matchingControl.value === 0) {
      matchingControl.setErrors({ mustMatch: true })
    } else {
      matchingControl.setErrors(null)
    }
  }
}

function ValidaData(campoParaValidar: string) {
  return (formGroup: FormGroup) => {
    const matchingControl = formGroup.controls[campoParaValidar]

    if (matchingControl.value) {
      if (matchingControl.errors && !matchingControl.errors.mustMatch) return

      const data = matchingControl.value

      if (data.length === 10 && moment(data, 'DD/MM/YYYY').isValid()) {
        matchingControl.setErrors(null)
      } else if (data.length === 10) {
        matchingControl.setErrors({ mustMatch: true })
      }

    }
  }
}



