import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Ocorrencia } from "../models/ocorrencia";

@Injectable({ providedIn: 'root' })
export class OcorrenciaService {

    constructor(private readonly http: HttpClient) { }

    buscaOcorrencias(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/ocorrencia/0/1000`)
        .pipe(
            tap(response => {
            return response
        } ))
    }

    buscaOcorrenciaPorId(id: string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/ocorrencia/${id}`)
        .pipe(
            tap(response => {
            return response
        } ))
    }

    salvaOcorrencia(ocorrencia: Ocorrencia): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/ocorrencia`, ocorrencia)
        .pipe(
            tap(response => {
                return response
            })
        )
    }

    removeOcorrencia(idOcorrencia: string): Observable<any> {
        return this.http.delete<any>(`${environment.apiUrl}/ocorrencia/${idOcorrencia}`)
        .pipe(
            tap(response => {
                return response
            })
        )
    }

}