import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {RouterModule} from "@angular/router";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SegmentoComponent } from "./segmento.component";
import { CadastroSegmentoComponent } from "./cadastro.component"

@NgModule({
  declarations: [
    SegmentoComponent,
    CadastroSegmentoComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    SegmentoComponent
  ]
})

export class SegmentoModule {}
