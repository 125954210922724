import { Component, OnDestroy, OnInit, } from '@angular/core';
import { Comercio } from 'src/app/models/comercio';
import { ComercioService } from 'src/app/services/comercio.service';

@Component({
  selector: 'comercio',
  templateUrl: 'comercio.template.html'
})
export class ComercioComponent implements OnDestroy, OnInit  {

  public nav:any;
  public comercios: Comercio[]

  public constructor(private readonly comercioService: ComercioService) {
    this.nav = document.querySelector('nav.navbar');
  }

  public ngOnInit():any {
    this.nav.className += " white-bg";
    const response = this.comercioService.buscaComercios()
    response.subscribe(obs => this.comercios = obs.content.sort((a,b) => {
      if(a.nomeFantasia.toLowerCase > b.nomeFantasia.toLowerCase) return 1
      else if(a.nomeFantasia.toLowerCase < b.nomeFantasia.toLowerCase) return -1
      return 0
    }))
  }

  public ngOnDestroy():any {
    this.nav.classList.remove("white-bg");
  }

  public removeComercio(idComercio: string): void {
    const index = this.comercios.findIndex( i => i.id === idComercio)
    const response = this.comercioService.removeComercio(idComercio)
    response.subscribe(obs => this.comercios.splice(index, 1))
  }

}
