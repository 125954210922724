import { Component, OnDestroy, OnInit, } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoriaContasReceber } from 'src/app/models/categoriaContasReceber';
import { CategoriaContasReceberService } from 'src/app/services/categoriaContasReceber.service';

@Component({
  selector: 'cadastrocategoria',
  templateUrl: 'cadastro.template.html'
})
export class CadastroCategoriaComponent implements OnDestroy, OnInit  {

  public registerForm: FormGroup
  public submitted = false
  public idCategoria: string

  public nav:any;

  public constructor(
    private readonly categoriaService: CategoriaContasReceberService,
    private readonly activeRoute: ActivatedRoute,
    private readonly route: Router,
    private formBuilder: FormBuilder
  ) {
    this.nav = document.querySelector('nav.navbar');
    this.activeRoute.params.subscribe(params => this.idCategoria = params.id);
  }

  public ngOnInit():any {
    this.nav.className += " white-bg";

    this.registerForm = this.formBuilder.group({
      id: null,
      nome: [null, Validators.required],
      descricao: [null, Validators.required],
      valor: [0.0, Validators.min(0.1)],
    })

    if (this.idCategoria) {
      const response = this.categoriaService.buscaCategoriaPorId(this.idCategoria)
      response.subscribe(obs => {
        this.preencheForm(obs)
      })
    }
  }

  get f() { return this.registerForm.controls }

  public ngOnDestroy():any {
    this.nav.classList.remove("white-bg");
  }

  onSubmit() {
    this.submitted = true
    if (this.registerForm.invalid) return
    this.salvaCategoria()
  }

  onReset() {
    this.submitted = false
    this.registerForm.reset()
  }

  private preencheForm(categoria: CategoriaContasReceber): void {
    this.registerForm.patchValue({
      id: categoria.id,
      nome: categoria.nome,
      descricao: categoria.descricao,
      valor: categoria.valor,
    })
  }

  private preencheCategoria(): CategoriaContasReceber {
    const recursoForm = this.registerForm.value

    const categoria = new CategoriaContasReceber()
    categoria.id = recursoForm['id']
    categoria.nome = recursoForm['nome']
    categoria.descricao = recursoForm['descricao']
    categoria.valor = recursoForm['valor']

    return categoria
  }

  private salvaCategoria() {
    const categoria = this.preencheCategoria()
    this.categoriaService.salvaCategoria(categoria).subscribe(() => {
      this.limpaForm()
    })
  }

  private limpaForm() {
    this.onReset()
    this.route.navigate(['/configuracoes/categoria'])
  }

}
