import { Endereco } from "./endereco"

export class Fornecedor {
    id: string
    nome: string
    cpfCnpj: string
    telefone: string
    celular: string
    email: string
    fisicaJuridica: string
    endereco: Endereco = new Endereco()
    idAssociacao: string
}