import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { environment } from '../../environments/environment';
import { Usuario } from "../models/usuario";

@Injectable({ providedIn: 'root' })
export class UsuarioService {
    
    constructor(private readonly http: HttpClient) { }

    buscaUsuarios(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/usuario/0/10`)
        .pipe(
            tap(response => {
            return response
        } ))
    }

    buscaUsuarioPorId(id: string): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/usuario/${id}`)
        .pipe(
            tap(response => {
            return response
        } ))
    }

    salvaUsuario(usuario: Usuario): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/usuario`, usuario)
        .pipe(
            tap(response => {
                return response
            })
        )
    }
}