import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, shareReplay } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Usuario } from '../models/usuario';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<Usuario>;
    public currentUser: Observable<Usuario>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<Usuario>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): Usuario {
        return this.currentUserSubject.value;
    }

    login(email: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}/login`, { email, password }, { observe: 'response' })
            .pipe(tap(response => {
                this.setSession(response) 
                shareReplay()
                return response
            } ))
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }

    private setSession(authResult) {
        // const token = authResult.token;
        // const payload = <JWTPayload> jwtDecode(token);
        // const expiresAt = moment.unix(payload.exp);

        localStorage.setItem('token', authResult.body.token);
        localStorage.setItem('currentUser', JSON.stringify(authResult.body));
        this.currentUserSubject.next(authResult.body);
        // localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
    }
}