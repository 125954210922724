import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'

// Services
import { AuthenticationService } from './services/authentication.service'
import { AuthGuard, JwtInterceptor } from './helpers'

// App views
import { DashboardsModule } from "./views/dashboards/dashboards.module"
import { AppviewsModule } from "./views/appviews/appviews.module"
import { AluguelModule } from "./views/aluguel/aluguel.module"
import { CategoriaModule } from "./views/categoria/categoria.module"
import { ComercioModule } from "./views/comercio/comercio.module"
import { ContasPagarModule } from "./views/contaspagar/contaspagar.module"
import { ContasReceberModule } from "./views/contasreceber/contasreceber.module"
import { FluxoCaixaModule } from "./views/fluxocaixa/fluxocaixa.module"
import { FornecedorModule } from "./views/fornecedor/fornecedor.module"
import { InformaisModule } from "./views/informais/informais.module"
import { MoradorModule } from "./views/morador/morador.module"
import { OcorrenciaModule } from "./views/ocorrencia/ocorrencia.module"
import { PerfilModule } from "./views/perfil/perfil.module"
import { RecursoModule } from "./views/recurso/recurso.module"
import { SegmentoModule } from "./views/segmento/segmento.module"
import { UsuarioModule } from "./views/usuario/usuario.module"

// App modules/components
import { LayoutsModule } from "./components/common/layouts/layouts.module";

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DashboardsModule,
    LayoutsModule,
    AppviewsModule,
    AluguelModule,
    CategoriaModule,
    ComercioModule,
    ContasPagarModule,
    ContasReceberModule,
    FluxoCaixaModule,
    FornecedorModule,
    InformaisModule,
    MoradorModule,
    OcorrenciaModule,
    PerfilModule,
    RecursoModule,
    SegmentoModule,
    UsuarioModule,
  ],
  providers: [
    AuthenticationService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
